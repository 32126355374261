import '../styles/templates/contact.scss'

import React from 'react'

import { PAGE_NAME } from '../../config/cms'
import { Layout } from '../components/layout'
import { PageHeading } from '../components/page-heading'
import { PageSubheading } from '../components/page-subheading'
import { usePageData } from '../hooks/cms/use-page'
import { PageComponentFactory } from '../utils/pages'
const TEMPLATE_NAME = PAGE_NAME.Contact

const getFilePath = file => `/assets/files/${file.split('/').pop()}`

const ContactPage = PageComponentFactory(() => {
  const { landingSection, membersSection, contactSection } = usePageData(
    PAGE_NAME.Contact
  )

  return (
    <Layout variant="secondary" buttonVariant='primary' page={TEMPLATE_NAME} pathname="/contact">
      <main className="contact-page">
        <section className="contact-page__landing-section">
          <div className="container">
            <PageHeading>{landingSection.heading}</PageHeading>
            <PageSubheading>{landingSection.caption}</PageSubheading>
          </div>
        </section>

        <section className="contact-page__list-section container">
          <div className="contact-page__list-section-members">
            {membersSection.map(
              ({ fullName, position, image, email }, index) => (
                <div key={index}>
                  <img src={getFilePath(image)} />
                  <div className="contact-page__list-section-members-details">
                    <h3>{email}</h3>
                    <h4>{fullName}</h4>
                    <h4>{position}</h4>
                  </div>
                </div>
              )
            )}
          </div>
          <div className="contact-page__list-section-contact">
            {contactSection.map(({ email, caption }, index) => (
              <div key={index}>
                <h3>{email}</h3>
                <h4>{caption}</h4>
              </div>
            ))}
          </div>
        </section>
      </main>
    </Layout>
  )
})

export default ContactPage
